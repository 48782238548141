/*** IMPORTS FROM imports-loader ***/
import "core-js/modules/es.promise";
import "core-js/modules/es.array.iterator";

import $ from 'jquery';
import AOS from 'aos';
import Headroom from "headroom.js";

// Add custom hasChildren selector
$.expr[':'].hasChildren = function (element, index, selector) {
	return $(element).children().length >= (selector[3] || 1);
};

// Wrap Swiper element
var wrapSwiper = function (element, childrenSelector = null, pagination = false, navigation = false) {
	var $element = $(element);
	$element.children(childrenSelector).addClass('swiper-slide');
	$element.wrapInner('<div class="swiper-wrapper" />');

	if (pagination) {
		$element.append('<div class="swiper-pagination" />');
	}

	if (navigation) {
		$element.append('<a class="swiper-button-prev"></a>');
		$element.append('<a class="swiper-button-next"></a>');
	}
};

// Init Animate On Scroll
AOS.init({
	once: true,
	easing: 'ease-in-sine'
});

$(async function () {
	var $html = $('html'),
		lang = $html.attr('lang') || 'en';

	// Navigation
	$('.navbar-burger[data-target]').each(function () {
		var $this = $(this);
		var $toggles = $this.add('#' + $this.data('target'));

		$this.click(function () {
			$toggles.toggleClass('is-active');
			$html.attr('data-lock', function (i, attr) {
				var active = attr === '1';

				// Set scrollbar width as padding
				var scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
				$html.css('padding-right', active ? 0 : scrollbarWidth);
				$toggles.css('padding-right', active ? 0 : scrollbarWidth);

				return active ? null : '1';
			});
		});

		$html.click(function (e) {
			if ($(e.target).is('body') && $toggles.hasClass('is-active')) {
				$this.trigger('click');
			}
		});
	});

	// Open sub menu on icon click
	$('.navbar-menu .navbar-item.has-dropdown').each(function () {
		var element = $(this),
			icon = element.find('.icon'),
			link = element.find('.navbar-link');

		icon.click(function () {
			link.toggleClass('is-active');
		});
	});

	// Close dropdown on click
	document.querySelectorAll('dl.languages > dt').forEach(el => {
		el.addEventListener('click', () => {
			el.parentElement.classList.add('clicked');
			el.parentElement.classList.toggle('open');
		});

		el.addEventListener('blur', () => {
			// Timeout is required for iOS
			setTimeout(() => {
				el.parentElement.classList.remove('clicked');
				el.parentElement.classList.remove('open');
			}, 0);
		});

		// Prevent mousedown in next sibling (dd) to prevent click event to bubble to blur
		el.nextElementSibling.addEventListener('mousedown', e => {
			e.preventDefault();
		});

		el.addEventListener('keydown', e => {
			// Space and Enter
			if (e.keyCode === 13 || e.keyCode === 32) {
				el.click();
				e.preventDefault();
			}
		});
	});


	// Header top affix
	$('header .top').each(function () {
		var headroom = new Headroom(this);
		headroom.init();
	});

	// Hero video skip link
	$('header .hero-video').each(function () {
		var $this = $(this);

		new IntersectionObserver(function (entries) {
			$.each(entries, function (i, entry) {
				$(entry.target)[(entry.isIntersecting ? 'add' : 'remove') + 'Class']('bottom');
			});
		}, {
				rootMargin: '-100% 0px 0px 0px'
			}).observe($this[0]);
	});

	// Popover click is-active
	$('.popover').each(function () {
		var element = $(this);

		element.find('.popover-trigger').click(function () {
			element.toggleClass('is-active');
		});

		element.find('.popover-close').click(function () {
			element.removeClass('is-active');
		});
	});

	// Popover close


	// Device detection
	var isMobile = false;
	if (/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent)
		|| /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.substr(0, 4))) isMobile = true;

	if (!isMobile) {
		$(window).scroll(function () {
			parallax();
		});

		function parallax() {
			var ev = {
				scrollTop: document.body.scrollTop || document.documentElement.scrollTop
			};
			ev.ratioScrolled = ev.scrollTop / (document.body.scrollHeight - document.documentElement.clientHeight);
			render(ev);
		}

		function render(ev) {
			var t = ev.scrollTop;
			var y = Math.round(t * 3 / 5) - 0;
			var yFaster = Math.round(t * 3 / 6) - 0;

			$('.parallax-right').css('background-position', 'right ' + y + 'px');
			$('.parallax-left').css('background-position', 'left ' + yFaster + 'px');
			$('.parallax-left, .parallax--right').css("display", "block");
		}

		parallax();
	}

	// Slideshow
	$('.column-image figure:hasChildren(2)').each(async function () {
		const Swiper = await import('swiper/js/swiper.js');

		wrapSwiper(this, null, true, false);


		if ($(this).find('.slide').length > 1) {
			new Swiper.default(this, {
				keyboard: true,
				loop: true,
				observer: true,
				observeParents: true,
				effect: 'fade',
				slideClass: 'slide',
				speed: 1000,
				autoplay: {
					delay: 5000
				},
				pagination: {
					el: '.swiper-pagination',
					clickable: true
				},
				on: {
					init: function () {
						// Refresh AOS, because activating Swiper changes layout
						AOS.refresh();
					}
				}
			});
		};
	});

	// Product slider
	$('.product-slider:hasChildren(2)').each(async function () {
		const Swiper = await import('swiper/js/swiper.js');

		wrapSwiper(this, null, false, false);

		new Swiper.default(this, {
			slidesPerView: 1,
			observer: true,
			observeParents: true,
			breakpointsInverse: true,
			breakpoints: {
				460: {
					slidesPerView: 2
				},
				600: {
					slidesPerView: 3
				},
				1024: {
					slidesPerView: 4
				},
				1216: {
					slidesPerView: 5
				},
				1408: {
					slidesPerView: 6
				},
				1920: {
					slidesPerView: 7
				}
			},
			loop: true,
			autoplay: {
				delay: 3000
			},
			keyboard: true,
			speed: 1500,
			on: {
				init: function () {
					// Refresh AOS, because activating Swiper changes layout
					AOS.refresh();
				}
			}
		});
	});

	// Slider
	$('.slider:hasChildren(2)').each(async function () {
		const Swiper = await import('swiper/js/swiper.js');

		wrapSwiper(this, null, false, true);

		new Swiper.default(this, {
			slidesPerView: 1,
			observer: true,
			observeParents: true,
			breakpointsInverse: true,
			spaceBetween: 40,
			breakpoints: {
				460: {
					slidesPerView: 2
				},
				600: {
					slidesPerView: 3
				},
				1024: {
					slidesPerView: 4
				}
			},
			loop: true,
			autoplay: {
				delay: 3000
			},
			keyboard: true,
			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev'
			},
			speed: 1500,
			on: {
				init: function () {
					// Refresh AOS, because activating Swiper changes layout
					AOS.refresh();
				}
			}
		});
	});

	// Filter form
	$('form.filter').each(function () {
		var $form = $(this),
			update = $form.data('update');

		var currentRequest = null,
			sendRequest = function (url, type = 'get', data = null) {
				currentRequest = $.ajax({
					type: type,
					url: url,
					data: data,
					beforeSend: function () {
						if (currentRequest !== null) {
							currentRequest.abort();
						}

						$(update).addClass('loading');
					},
					success: function (data) {
						var $data = $(data);
						$(update).replaceWith($data.find(update));
						loadModals();
					}
				});
			};

		$form.submit(function (e) {
			var data = $form.serialize();
			sendRequest($form.attr('action'), $form.attr('method'), data);

			// Update download link
			$form.find('a.is-download').attr('href', function () {
				return '?' + data + '&download=True';
			});

			e.preventDefault();
		});

		$form.on('reset', function () {
			sendRequest($form.attr('action'), $form.attr('method'));
		});

		$form.find('select, input[type=checkbox], input[type=radio]').change(function () {
			// Enable auto-submit
			$form.submit();
		});

		// Hide submit
		$form.find('[type=submit]:not(.is-search), [type=reset]').toggleClass('is-hidden');
	});


	// Modals
	function loadModals() {
		$('#product-overview').each(function () {
			var $children = $(this).find('.product-card');
			var i = 0;
			var totalChildren = $children.length;

			$children.each(function () {
				var $this = $(this),
					modal = $this.find('.modal'),
					card = modal.find('.modal-card');

				$this.addClass('has-js');

				if (modal) {
					$this.on('click', function (event) {
						modal.addClass('is-active');
						event.stopPropagation();
					});

					modal.find('.modal-background, button').on('click', function (event) {
						modal.removeClass('is-active');
						event.stopPropagation();
					});
				}

				if (totalChildren > 1) {

					var prevModal = 0;
					if (i === 0) {
						prevModal = totalChildren - 1;
					}
					else {
						prevModal = i - 1;
					}

					card.append($('<div class="modal-prev">previous</div>').on('click', function () {
						modal.removeClass('is-active');
						$($children[prevModal]).find('.modal').addClass('is-active');
						event.stopPropagation();
					}));

					var nextModal = 0;
					if (i === totalChildren - 1) {
						nextModal = 0;
					}
					else {
						nextModal = i + 1;
					}

					card.append($('<div class="modal-next">next</div>').on('click', function () {
						modal.removeClass('is-active');
						$($children[nextModal]).find('.modal').addClass('is-active');
						event.stopPropagation();
					}));
				}

				i++;
			});

			// Slideshow
			$('.column-image-slide figure:hasChildren(2)').each(async function () {
				const Swiper = await import('swiper/js/swiper.js');

				wrapSwiper(this, null, true, false);


				if ($(this).find('.slide').length > 1) {
					new Swiper.default(this, {
						keyboard: true,
						loop: true,
						observer: true,
						observeParents: true,
						effect: 'fade',
						slideClass: 'slide',
						speed: 1000,
						autoplay: {
							delay: 5000
						},
						pagination: {
							el: '.swiper-pagination',
							clickable: true
						},
						on: {
							init: function () {
								// Refresh AOS, because activating Swiper changes layout
								AOS.refresh();
							}
						}
					});
				};
			});

		});

		$('#signup').each(function () {
			var $this = $(this),
				button = $this.find('.button-signup'),
				modal = $this.find('.modal');

			$this.addClass('has-js');

			if (modal) {
				button.on('click', function (event) {
					modal.addClass('is-active');
					event.stopPropagation();
				});

				modal.find('.modal-background, .modal-close').on('click', function (event) {
					modal.removeClass('is-active');
					event.stopPropagation();
				});
			}
		});
	}
	loadModals();

	// Google maps
	$('.maps').each(async function () {
		var maps = $(this),
			apiKey = maps.data('api-key'),
			dataGeolocations = maps.data('geolocations').split(';');

		const loadGoogleMapsApi = await import(/* webpackChunkName:"load-google-maps-api" */ 'load-google-maps-api');
		const googleMaps = await loadGoogleMapsApi.default({
			key: apiKey
		});

		var bounds = new google.maps.LatLngBounds();

		var map = new googleMaps.Map(document.querySelector('.maps'), {
			zoom: 17,
			zoomControl: false,
			//maxZoom: 8,
			mapTypeControl: false,
			scaleControl: false,
			streetViewControl: false,
			rotateControl: false,
			fullscreenControl: false,
			styles: [
				{
					"featureType": "poi",
					"elementType": "labels.text",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "poi.business",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "road",
					"elementType": "labels.icon",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				},
				{
					"featureType": "transit",
					"stylers": [
						{
							"visibility": "off"
						}
					]
				}
			]
		});

		var image = {
			url: require('../images/maps-marker.png').default,
			size: new google.maps.Size(80, 80),
			scaledSize: new google.maps.Size(80, 80),
			origin: new google.maps.Point(0, 0),
			anchor: new google.maps.Point(40, 40)
		};

		for (var i = 0; i < dataGeolocations.length; i++) {
			var latLng = dataGeolocations[i].split(',');

			var marker = new google.maps.Marker({
				position: {
					lat: parseFloat(latLng[0]),
					lng: parseFloat(latLng[1])
				},
				map: map,
				icon: image,
				optimized: false
			});

			bounds.extend(marker.position);
		}

		// Set zoom bounds are fit
		var zoomChangeBoundsListener;
		google.maps.event.addListener(map, 'zoom_changed', function () {
			zoomChangeBoundsListener =
				google.maps.event.addListener(map, 'bounds_changed', function (event) {
					if (this.getZoom() > 15 && this.initialZoom === true) {
						this.setZoom(15);
						this.initialZoom = false;
					}
					google.maps.event.removeListener(zoomChangeBoundsListener);
				});
		});


		var myoverlay = new google.maps.OverlayView();
		myoverlay.draw = function () {
			// add an id to the layer that includes all the markers so you can use it in CSS
			this.getPanes().markerLayer.id = 'markerLayer';
		};
		myoverlay.setMap(map);


		map.initialZoom = true;
		map.fitBounds(bounds, { top: 120 });
	});

	// Paged news
	var pagedOverview = $('.paged-overview');
	if (pagedOverview.length) {
		await import(/* webpackChunkName: "waypoints" */ 'waypoints/lib/jquery.waypoints.js');
		await import(/* webpackChunkName: "waypoints" */ 'waypoints/lib/shortcuts/infinite.js');

		pagedOverview.each(function () {
			var overview = $(this);

			overview.find('a[rel=next]').one('click', function (e) {
				new Waypoint.Infinite({
					element: overview[0],
					container: overview.find('.columns'),
					items: '.overview-item',
					more: 'a[rel=next]',
					offset: function () {
						return Waypoint.offsetAliases['bottom-in-view'].call(this) + 200;
					},
					onAfterPageLoad: function (items) {
						// Fade in added items
						const delay = 40;
						items.css('opacity', 0).each(function (i) {
							var item = $(this);
							setTimeout(function () {
								item.css({
									opacity: 1,
									transition: 'opacity ease 120ms'
								});
							}, i * delay);
						});
					}
				});

				overview.find('.pagination-container').hide();
				e.preventDefault();
			});
		});
	}

	// Virtual tour
	$('.virtual-tour').each(function () {
		// Add click action + delay on mouse out
		var element = $(this);
		element.find('.overlay').click(function () {
			$(this).addClass('is-hidden');
		});
	});

	// Lightbox
	$('body:has([rel=lightbox])').each(async function () {
		await import(/* webpackChunkName: "lightgallery" */ 'lightgallery');
		await import(/* webpackChunkName: "lightgallery" */ 'lg-video');

		$(this).lightGallery({
			selector: '[rel=lightbox]',
			download: false,
			thumbnail: false,
			fullScreen: false,
			actualSize: false,
			hash: false,
			videoMaxWidth: '1920px'
		});
	});

	// Smooth scroll
	$('a[href*=\\#]').click(function () {
		var target = $(this.hash),
			top = $('header .top')[0];

		if (!target.length) {
			target = $('[name=' + this.hash.slice(1) + ']');
		}

		if (target.length) {
			$('html, body').animate({
				scrollTop: target.offset().top - 100
			}, 250);

			return false;
		}
	});
});